import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./pages/home";
import Docs from "./pages/docs";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route} from 'react-router-dom';
import GlobalFonts from "./fonts/fonts";
import Menubar from "./components/menubar/menubar";
import {Redirect, Switch} from "react-router";
import Container from "react-bootstrap/Container";
import './reactotronConfig'

ReactDOM.render(
    <React.StrictMode>
        <Container fluid="xl" className="pl-0 pr-0">
            <BrowserRouter>
                <Menubar />
                <Switch>
                    <Route path="/home" component={Home} />
                    <Route path="/docs" component={Docs} />
                </Switch>
                <Route exact path="/">
                    <Redirect to="/home"/>
                </Route>
            </BrowserRouter>
            <GlobalFonts />
        </Container>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
