import '../../css/index.css';
import './css/welcome.css';
import {Container} from "react-bootstrap";

function Welcome() {
    return (
        <Container fluid={"xl"} id="welcome">
            <h1 className="h1_">What is j8a?</h1>
            <p className="p_">j8a [ dʒʌbbʌ ] is a production grade, &nbsp;
                <a href="https://www.apache.org/licenses/LICENSE-2.0">Apache licensed</a>,&nbsp;
                <a href="https://tools.ietf.org/html/rfc8446">TLS 1.3</a> compliant,&nbsp;
                <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Proxy_servers_and_tunneling">reverse
                    proxy</a>,&nbsp;
                <a href="https://tools.ietf.org/html/rfc7540">HTTP/2</a> server for REST APIs. It is written in Go and
                highly optimised for API security and runtime efficiency. It's design goal is to function as a <a
                    href="https://www.thoughtworks.com/radar/platforms/overambitious-api-gateways">lightweight API
                    gateway</a> for micro-services. The implementation scales well and takes advantage of
                go's efficient multiprocessor support, yet shrinks to single file binary distribution only 11MB in size.
                J8a serves 5k HTTP POST requests/sec using only 70MB RAM under test conditions.
            </p>
            <p className="p_">
                You can find the <a href="https://github.com/simonmittag/j8a">source code on github</a> and as well as pre-built <a
                href="https://github.com/simonmittag/j8a/releases/tag/v1.1.1">binaries for linux and darwin</a>.  We welcome <a
                    href="https://github.com/simonmittag/j8a/issues">issues</a>
                &nbsp;and pull-requests.
                The current stable version, released on 04/12/2023, is <img
                    src="https://img.shields.io/docker/v/simonmittag/j8a/v1.1.1" alt="current version v1.1.1"/>.
            </p>
        </Container>
    );
}

export default Welcome;
