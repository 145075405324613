import './css/menubar.css';
import logo from './images/j8alogo.svg';
import {NavLink} from "react-router-dom";

function Menubar() {
    return (
        <nav id="menubar" className="mynavbar navbar-expand-lg navbar-light menubackground">
            <img src={logo}
                 alt='j8a logo'
                 className='navbar-brand j8alogo'/>
            <button className="navbar-toggler marginright"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    <li className="my-navbar-nav nav-item">
                        <NavLink to="/home" className="nav-link" activeClassName="active">Home</NavLink>
                    </li>
                    <li className="my-navbar-nav nav-item">
                        <NavLink to="/docs" className="nav-link" activeClassName="active">Docs</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
);
}

export default Menubar;
