import { createGlobalStyle } from 'styled-components';

import GoodTimesRgRegularWoff from './GoodTimesRg-Regular.woff';
import GoodTimesRgRegularWoff2 from './GoodTimesRg-Regular.woff2';
import BebasNeueRegularWoff2 from './BebasNeue-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Good Times';
        src: local('Good Times'), local('GoodTimes'),
        url(${GoodTimesRgRegularWoff2}) format('woff2'),
        url(${GoodTimesRgRegularWoff}) format('woff');
        font-style: normal;
    }
    @font-face {
        font-family: 'Bebas Neue';
        src: local('Bebas Neue'), local('BebasNeue'),
        url(${BebasNeueRegularWoff2}) format('woff2');
        font-style: normal;
    }
`;
