import React from "react";
import {Container} from "react-bootstrap";
import {CopyBlock, paraisoLight as theme} from "react-code-blocks";
import raw from "raw.macro";
import '../css/index.css';

function Docs() {
    const code = raw("./config.yml")
    const ab = "policies:\n" +
        "  ab:\n" +
        "    - label: green\n" +
        "      weight: 0.8\n" +
        "    - label: blue\n" +
        "      weight: 0.2"
    const abroute = "routes:\n" +
        "  - path: /downstreampath\n" +
        "    resource: upstreamresource\n" +
        "    policy: ab"
    const abres = "resources:\n" +
        "  upstreamresource:\n" +
        "    - labels:\n" +
        "        - green\n" +
        "      url:\n" +
        "        scheme: http://\n" +
        "        host: localhost\n" +
        "        port: 60083\n" +
        "    - labels:\n" +
        "        - blue\n" +
        "      url:\n" +
        "        host: localhost\n" +
        "        port: 60084"
    const jwt = "jwt:\n" +
        "  myjwt:\n" +
        "    alg: RS256\n" +
        "    key: |\n" +
        "      -----BEGIN PUBLIC KEY-----\n" +
        "      ...\n" +
        "      -----END PUBLIC KEY-----\n";
    const jwtjwks = "jwtjwks:\n" +
        "    alg: PS256\n" +
        "    jwksUrl: http://localhost:60083/mse6/jwks";
    const jwtclaims = "jwt:\n" +
        "  myjwt:\n" +
        "    claims:\n" +
        "      - .sub | select(.==\"subscriber\")\n" +
        "      - .sub | select(.==\"admin\")\n";
    const jwtclaims2 = "jwt:\n" +
        "  myjwt:\n" +
        "    claims:\n" +
        "      - nonce"
    const routes = "routes:\n" +
        "  - path: /downstreampath\n" +
        "    transform: /transformedpath\n" +
        "    resource: upstreamresource\n" +
        "    jwt: myjwt\n" +
        "    policy: ab"
    const routes2 = "routes:\n" +
        "  - path: /index.html\n" +
        "    pathType: exact\n" +
        "    host: sub.domain.com\n" +
        "    resource: upstreamresource\n";
    const routes3 = "routes:\n" +
        "  - path: /\n" +
        "    host: www.emoji😊😊😊.org\n" +
        "    resource: upstreamresource\n";
    const resource = "resources:\n" +
        "  upstreamresource:\n" +
        "    - labels:\n" +
        "        - green\n" +
        "      url:\n" +
        "        scheme: http://\n" +
        "        host: localhost\n" +
        "        port: 60083\n" +
        "    - labels:\n" +
        "        - blue\n" +
        "      url:\n" +
        "        host: localhost\n" +
        "        port: 60084"
    const tls = "tls:\n" +
        "  port: 443\n" +
        "  cert: |\n" +
        "    -----BEGIN CERTIFICATE-----\n" +
        "    ...\n" +
        "    -----END CERTIFICATE-----\n" +
        "  key: |\n" +
        "    -----BEGIN PRIVATE KEY-----\n" +
        "    ...\n" +
        "    -----END PRIVATE KEY-----\n" +
        "  acme:\n" +
        "    provider: letsencrypt\n" +
        "    domains:\n" +
        "      - yourdomain.com\n" +
        "      - www.yourdomain.com\n" +
        "    email: noreply@example.org\n" +
        "    gracePeriodDays: 30"
    const http = "http:\n" +
        "  port: 80\n" +
        "  redirecttls: false"
    return (
        <Container id="docs">
            <h1 className="h1_">Configuration</h1>
            <p className="p_">J8a uses policy based configuration in yml format. You can
                specify <code>J8ACFG_YML</code> as
                an environment variable to the process or use <code>j8a -c</code> on the cli with a configuration file
            </p>
            <h3 className="h3_">Options</h3>
            <CopyBlock text={code}
                       language="yaml"
                       theme={theme} />
            <h2 className="h2_">timeZone</h2>
            <hr className="hr_" />
            <code className="tag">Default: UTC</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Specify local time zone for all server events in the log. See <a href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones">valid TZ identifiers</a>
            </p>

            <h2 className="h2_">logLevel</h2>
            <hr className="hr_" />
            <code className="tag">Default: info</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Specify log level for the server. Must be one of <code>trace</code>, <code>debug</code>, <code>info</code>, or <code>warn</code>. J8a logs upstream events at <code>trace</code> level. This does affect performance of the overall server and
                greatly increases log file size. Successful downstream response events are logged at <code>debug</code> level, server internal apm
                monitoring events at <code>info</code> level, and individual downstream request errors at <code>warn</code> level. The are no error level events.

                The server logs <code>fatal</code> during failed bootstrap. This only happens if the config file fails validation and
                the server cannot properly start.
            </p>

            <h2 className="h2_">readTimeoutSeconds</h2>
            <hr className="hr_" />
            <code class="tag">Default: 5</code>
            <code class="tag">Connection</code>
            <code class="tag">Downstream</code>
            <code class="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Specify max. time in seconds the server should wait for the downstream connection to read the last byte
                from incoming HTTP request, including headers and body. Read is aborted after this timeout fires
                and the TCP connection is recycled. The higher you set this value, the longer the
                server will wait to complete slow reads, however the longer it will also take before
                recycling an abandoned connection and freeing associated network resources.
            </p>

            <h2 className="h2_">roundTripTimeoutSeconds</h2>
            <hr className="hr_" />
            <code className="tag">Default: 10</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Max. time in seconds the server may spend proxying
                each request, inclusive of sending the downstream response. This includes the time spent processing any
                upstream requests, including repeated attempts. Note this value should be larger
                than <code>readTimeSeconds</code> to avoid side effects.
            </p>

            <h2 className="h2_">idleTimeoutSeconds</h2>
            <hr className="hr_" />
            <code className="tag">Default: 5</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Time in seconds the server waits after reading the last byte
                from an incoming HTTP request, before recycling the underlying connection to the remote IP.
                Keeping this value low will avoid accumulating a large number of abandoned socket
                connections. Downstream HTTP clients will typically remain connected using HTTP KeepAlive until
                this timeout fires.
            </p>

            <h2 className="h2_">http</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Define a HTTP block to listen for <code>HTTP/1.1</code> and <code>HTTP/2</code> on the
                specified <code>port</code>. Set <code>redirecttls</code> to <code>true</code> (defaults to <code>false</code>) to
                enable <code>HTTP 308</code> permanent redirection of all URLs to the listener configured using
                the <code>TLS</code> config option.
            </p>
            <CopyBlock text={http}
                       language="yaml"
                       theme={theme} />
            <p className="p_">
                The <code>HTTP</code> config option can be omitted, however one of <code>HTTP</code> or <code>TLS</code> must be configured.
            </p>

            <h2 className="h2_">port</h2>
            <hr className="hr_" />
            <code className="tag">Required</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Http</code>
            <code className="tag">Stable</code>
            <p className="p_">
                The TCP socket port for the <code>HTTP</code> listeners. Value must be specified if HTTP option is configured, no default provided.
                Range from <code>1</code> to <code>65534</code>
            </p>

            <h2 className="h2_">redirecttls</h2>
            <hr className="hr_" />
            <code className="tag">Default: false</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Http</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Set this to true to enable <code>HTTP 308</code> permanent redirection of all URLs to the listener configured using
                the <code>TLS</code> config option.
            </p>

            <h2 className="h2_">tls</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Define a TLS block to listen for <code>TLS 1.2</code> and <code>TLS 1.3</code> on the
                specified <code>port</code>. Alternatively specify both <code>cert</code> and <code>key</code> directly
                inside the configuration block, or configure an <code>acme</code> provider, but not both at the same time.
            </p>
            <CopyBlock text={tls}
                       language="yaml"
                       theme={theme} />
            <p className="p_">
                The <code>TLS</code> config option can be omitted, however one of <code>HTTP</code> or <code>TLS</code> must be configured.
            </p>

            <h2 className="h2_">port</h2>
            <hr className="hr_" />
            <code className="tag">Required</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Tls</code>
            <code className="tag">Stable</code>
            <p className="p_">
                The TCP socket port for the <code>TLS</code> listener. Value must be specified, no default provided. Range from <code>1</code> to <code>65534</code>
            </p>

            <h2 className="h2_">cert</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Tls</code>
            <code className="tag">Stable</code>
            <p className="p_">
                A TLS certificate chain in <a href="https://tools.ietf.org/html/rfc7468">PEM format</a>. May contain
                a single x509 certificate or a chain, including intermediate and root certs. If no root
                certificate is supplied, the server will attempt to build a verified chain based on your OS
                trust store configuration. To avoid this behaviour, i.e. for docker containers you should include the
                root certificate at the end of the chain. <code>cert</code> is optional, however one of <code>cert</code> or <code>acme</code> is required.
            </p>

            <h2 className="h2_">key</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Tls</code>
            <code className="tag">Stable</code>
            <p className="p_">
                The private key matching a TLS certificate, supplied via the <code>cert</code> option. Supports <a
                href="https://tools.ietf.org/html/rfc8017">PKCS1</a> key format without passphrases. <code>PKCS8</code> passphrases
                for private keys are not supported for security reasons and should be removed with i.e. openssl prior to adding the key to
                configuration. <code>key</code> is optional, however one of <code>key</code> or <code>acme</code> is required.
            </p>

            <h2 className="h2_">acme</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Tls</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Configure an auto-renewing TLS certificate using the <code>ACME</code> protocol described in RFC8555.
                Supported providers are: <code>letsencrypt</code>. All listed <code>domains</code> must be configured
                to point at the server's IP address to allow for the ACME provider to perform validation.

                J8a performs HTTP validation, so a valid <code>HTTP</code> block must be configured using port <code>80</code> when
                specifying this option. Please note that HTTP validation requires fully qualified domain names, and
                wildcard certificates are not supported. Certificates auto-renew in <code>gracePeriodDays</code> days prior to expiry with zero downtime, with range
                1-30. Supports registration <code>email</code> parameter, used to accept terms of service on users behalf.
            </p>


            <h2 className="h2_">maxBodyBytes</h2>
            <hr className="hr_" />
            <code className="tag">Default: 2097152</code>
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Downstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Limit the maximum amount of bytes the server is allowed to read on each incoming HTTP request to avoid
                network resource starvation and denial of service attacks. J8a will abort reading individual HTTP
                requests
                after this threshold is hit and stop allocating additional memory. Reads will abort after <code>n</code> bytes disregarding the value of HTTP header <code>Content-Length</code> on any incoming requests.
            </p>

            <h2 className="h2_">socketTimeoutSeconds</h2>
            <hr className="hr_" />
            <code className="tag">Default: 3</code>
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Upstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                This option controls roundtrip time for establishing new TCP connections. J8a waits
                for <code>n</code> seconds until a socket is established with a <code>resource</code>.
                This includes time required to perform the TLS handshake depending on the type of resource.
            </p>


            <h2 className="h2_">readTimeoutSeconds</h2>
            <hr className="hr_" />
            <code className="tag">Default: 10</code>
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Upstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Specifies the wait period allowed to read the entire upstream response <i>after</i> a socket connection
                has been established. Keeping this value in the low seconds will prevent hanging on to stalling or slow
                upstream responses. When this timeout fires, and the <code>maxAttempts</code> option
                is set J8a will abort the current upstream attempt and start a new one.
            </p>

            <h2 className="h2_">idleTimeoutSeconds</h2>
            <hr className="hr_" />
            <code className="tag">Default: 120</code>
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Upstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Specifies the wait period, idle upstream connections spend in the connection pool
                before being closed by the server. Set this to a high value if you experience longer breaks between
                individual upstream requests. Effectively limits idle connections from dangling.
            </p>

            <h2 className="h2_">maxAttempts</h2>
            <hr className="hr_" />
            <code className="tag">Default: 1</code>
            <code className="tag">Connection</code>
            <code className="tag">Upstream</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                The server may retry any unsuccessful, <i>repeatable</i> HTTP request <code>(GET, HEAD, OPTIONS, TRACE,
                PUT, DELETE)</code> up to <code>n</code> times
                against an upstream resource before returning an error response to the downstream client.
            </p>

            <h2 className="h2_">poolSize</h2>
            <hr className="hr_" />
            <code className="tag">Default: 32768</code>
            <code className="tag">Connection</code>
            <code className="tag">Upstream</code>
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Maximum size of the socket connection pool for upstream resources. Contains idle TCP connections for HTTP
                and TLS connections.
            </p>

            <h2 className="h2_">tlsInsecureSkipVerify</h2>
            <hr className="hr_" />
            <code className="tag">Default: false</code>
            <code className="tag">Optional</code>
            <code className="tag">Connection</code>
            <code className="tag">Upstream</code>
            <code className="tag">Stable</code>
            <p className="p_">
                This option skips the host name validation and certificate chain verification of upstream connections
                that use TLS. Enable this only for testing or if you know what you are doing, i.e if using a self-signed
                TLS certificate in a private upstream network. Note this option does not affect downstream TLS config.
            </p>

            <h2 className="h2_">policies</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Alpha</code>
            <p className="p_">
                Routing policies are a new feature, with a/b currently the only policy supported.
                Begin with declaring a named policy with labels and their weights.
            </p>
            <CopyBlock text={ab}
                       language="yaml"
                       theme={theme} />
            <p className="p_">
                 Next, specify one or multiple labels for each upstream URL of a resource. Here,
                we are routing 80% of traffic to one of the URLs labelled green and 20% to blue.
            </p>
            <CopyBlock text={abres}
                       language="yaml"
                       theme={theme} />
            <p className="p_">
                Finally, attach the policy to an individual route as follows for it to take effect.
            </p>
            <CopyBlock text={abroute}
                       language="yaml"
                       theme={theme} />

            <h2 className="h2_">jwt</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Use the JWT option to configure JWT bearer token signature and claim verification for server routes.
                J8a supports all <a href="https://tools.ietf.org/html/rfc7518">JWA</a> signature algorithms <code>(RS256, RS384, PS256, PS384, PS512, HS256, HS384, HS512, ES256, ES384, ES512)</code>.
                Public keys can be loaded in <code>PKIX</code> or <code>x509</code> encoding from a pem block, or from a remote JWKS URL.
                For public keys, the server supports JWKS key rotation and will periodically refresh key contents.
            </p>
            <h3 className="h3_">RS256 public key loaded from pem block</h3>
            <CopyBlock text={jwt}
                       language="yaml"
                       theme={theme} />
            <h3 className="h3_">PS256 public key loaded from JWKS URL</h3>
            <CopyBlock text={jwtjwks}
                       language="yaml"
                       theme={theme} />
            <p className="p_">
                Note that <code>alg</code> is a required option and the server will verify your key matches.
                Once your jwt config is applied to a route, only signatures of this type are permitted. J8a does not
                support JWKS URLs with changing algorithms for security reasons. 
            </p>

            <h2 className="h2_">acceptableSkewSeconds</h2>
            <hr className="hr_" />
            <code className="tag">Default: 120</code>
            <code className="tag">Optional</code>
            <code className="tag">Jwt</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Allow timed based Jwt token claims such as <code>nbf</code>, <code>iat</code> or <code>exp</code> to validate
                correctly for +/- acceptableSkewSeconds, to correct for clock variance on your system.
            </p>

            <h2 className="h2_">claims</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Jwt</code>
            <code className="tag">Stable</code>
            <p className="p_">
                You may want to validate JWT claims for each incoming request on a route to authorize the end user. This
                is efficient to do at runtime to prevent upstream servers from being flooded with traffic. Claims may
                be simple key names or <a href="https://stedolan.github.io/jq/">jq</a> expressions if you require
                testing for claim values.
            </p>
            <p className="p_">
                Multiple claims are evaluated with <code>OR</code> and validation will pass at the first match.
            </p>
            <h3 className="h3_">Match either value for the JWT sub claim</h3>
            <CopyBlock text = {jwtclaims}
                       language="yaml"
                       theme={theme}/>
            <h3 className="h3_">Match only JWT tokens that claim a <code>nonce</code></h3>
            <CopyBlock text = {jwtclaims2}
                       language="yaml"
                       theme={theme}/>
            <h2 className="h2_">routes</h2>
            <hr className="hr_" />
            <code className="tag">Required</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Routes describe the basic mechanics of proxying. Use <code>route</code> to map a <code>path</code>, <code>host</code> or both to an upstream <code>resource</code>.
                Routes can be matched as <code>pathType</code> <i>prefix</i> or <i>exact</i>, where <i>exact</i> takes precedence over the former. Routes with the
                <code>host</code> property specified take precedence over those without and J8a will attempt to match more specific hosts with subdomains over more generic ones.
                Similarly, more specific paths take precedence over shorter ones. You can use the <code>transform</code> option to URL rewrite the path stem of an incoming HTTP request before sending
                it upstream.
            </p>
            <h3 className="h3_">Mapping a route</h3>
            <CopyBlock text = {routes}
                       language="yaml"
                       theme={theme}/>
            <p className="p_">
                In the above example <code>/downstreampath</code> is mapped to a <code>resource</code> named <i>upstreamresource</i> and
                the path will be transformed before the actual proxying takes place. Each request will be validated against
                the <i>myjwt</i> <code>jwt</code> config and has to pass validation before proxying. Upstream resources will
                be allocated, using the <i>ab</i> <code>policy</code>
            </p>
            <CopyBlock text = {routes2}
                       language="yaml"
                       theme={theme}/>
            <p className="p_">
                In this example a second route is introduced which takes precedence with <code>pathType</code> <i>exact</i>. It matches
                only incoming requests with a HTTP <code>host</code> header value of <i>sub.domain.com</i>. Note, that any route with a <code>host</code> property
                will attempt matching before those without.
            </p>

            <CopyBlock text = {routes3}
                       language="yaml"
                       theme={theme}/>
            <p className="p_">
                Now we add a third route. It's <code>pathType</code> is not set and defaults to <i>prefix</i>. It matches all resources
                under a specific host. Note, you may specify unicode values as hostnames, which are translated to punycode internally.
            </p>

            <h2 className="h2_">resources</h2>
            <hr className="hr_" />
            <code className="tag">Required</code>
            <code className="tag">Stable</code>
            <p className="p_">
                Define upstream resources in this block. The server supports <code>HTTP</code>, <code>HTTPS</code>, <code>WS</code> and <code>WSS</code> as URL schemes. Attach a <code>label</code> as required by your policy. Note, J8a performs
                DNS lookup using your OS. In the example below, <i>upstreamresource</i> is made up of two URLs, each with a set of
                separate labels.
            </p>
            <h3 className="h3_">Defining a resource</h3>
            <CopyBlock text = {resource}
                       language="yaml"
                       theme={theme}/>
            <p className="p_">

            <h2 className="h2_">disableXRequestInfo</h2>
            <hr className="hr_" />
            <code className="tag">Optional</code>
            <code className="tag">Stable</code>
            <code className="tag">Default: false</code>
            <p className="p_">
                Disables internal debug feature of server that allows X-Request-Info HTTP header to log upstream request
                data at info level.
            </p>
                            
            </p>
        </Container>
    );
}

export default Docs;
