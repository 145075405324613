import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './css/features.css';
import '../../css/index.css';
import {Container, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import aws from "./images/aws.png";
import nginx from "./images/nginx.png";
import route from "../welcome/images/route.gif";

function Features() {
    return (
        <Container fluid={"xl"} id="features">
            <h3 className="h3_">How does it work?</h3>
            <img src={route} alt={"j8a features"} className="routeImg" k/>
            <p className="p_">
                J8a matches your URL <code>routes</code> forwards
                traffic to separate upstream server <code>resources</code>.
                It supports load balancing with <code>policies</code> and can recover failed requests. In a nutshell, <b>it makes multiple
                upstream servers appear as one to the client</b>. It does all that with smart defaults out of the box,
                like A+ TLS ciphers and conservative network timeouts. J8a is
                built for a DevOps audience that understands declarative configuration management as code and requires docker
                support. It is built to understand HTTP request tracing, JWT security, expiring TLS certificates and
                much more. Have a look at the <Link to="/docs">docs</Link> as a starting point.
            </p>


            <h3 className="h3_">Feature Comparison</h3>
            <p className="p_">
                We understand a server like J8a does not exist in a vacuum. You have a micro-service architecture that may come
                with existing tooling or are considering other platforms. The table below illustrates j8a key features and how they fit in
                in with some of the competition.
            </p>
            <Table striped bordered size="sm">
                <thead>
                <tr>
                    <th rowSpan={2}></th>
                    <th rowSpan={2} className={"text-center j8a-logo"}>J8A</th>
                    <th rowSpan={2} className={"text-center"}><img src={nginx} alt={"nginx logo"} className={"nginx-logo"}/></th>
                    <th colSpan={2} className={"text-center"}><img src={aws} alt={"aws logo"} className={"aws-logo"}/></th>
                </tr>
                <tr>
                    <th className={"text-center"}>ALB</th>
                    <th className={"text-center"}>APIGW</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={"features"}>Fast! 5k POST req/s</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                </tr>
                <tr>
                    <td className={"features"}>HTTP/1.1 & HTTP/2 support</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                </tr>
                <tr>
                    <td className={"features"}>TLS1.2 / TLS1.3 SSLLabs A+ OOB</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                </tr>
                <tr>
                    <td className={"features"}>RFC 7932 Brotli encoding</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                </tr>
                <tr>
                    <td className={"features"}>RFC 8555 ACME Support</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                </tr>
                <tr>
                    <td className={"features"}>RFC 6455 Websocket Support</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                </tr>
                <tr>
                    <td className={"features"}>Docker friendly</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                </tr>
                <tr>
                    <td className={"features"}>IaC config</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center green"}>✔</td>
                </tr>
                <tr>
                    <td className={"features"}>JWT token validation</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center green"}>✔</td>
                </tr>
                <tr>
                    <td className={"features"}>Rewrite upstream 500s as JSON</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center green"}>✔</td>
                </tr>
                <tr>
                    <td className={"features"}>Individual HTTP req trace</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                </tr>
                <tr>
                    <td className={"features"}>Zero dep. single binary</td>
                    <td className={"text-center green"}>✔</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                    <td className={"text-center red"}>✖</td>
                </tr>

                </tbody>
            </Table>
        </Container>
    );
}

export default Features;
