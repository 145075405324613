import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './css/gettingstarted.css';
import {Container} from "react-bootstrap";
import React from "react";
import {CopyBlock, paraisoLight as theme} from "react-code-blocks";
import {Link} from "react-router-dom";

function Gettingstarted() {
    const j8a = "λ docker pull simonmittag/j8a\n" +
        "Using default tag: latest\n" +
        "docker.io/simonmittag/j8a:latest\n" +
        "λ";

    const j8a2 = "λ export J8ACFG_YML=\"---\n" +
        "connection:\n" +
        "  downstream:\n" +
        "    http:\n" +
        "      port: 8080\n" +
        "routes:\n" +
        "  - path: \"/\"\n" +
        "    transform: \"/mse6/\"\n" +
        "    resource: mse6\n" +
        "resources:\n" +
        "  mse6:\n" +
        "    - url:\n" +
        "        scheme: http://\n" +
        "        host: host.docker.internal\n" +
        "        port: 8081\"\n" +
        "λ "

    const j8a3 = "λ docker run -e J8ACFG_YML -p8080:8080 simonmittag/j8a\n" +
        "{\"level\":\"info\",\"srvId\":\"32f7d3da\",\"time\":\"2021-02-10T21:14:05Z\",\"message\":\"j8a v0.7.3 listening in HTTP mode on port 8080...\"}\n" +
        "{\"level\":\"debug\",\"srvId\":\"32f7d3da\",\"pid\":1,\"cpuCorePct\":\"0.00\",\"memPct\":\"0.35\",\"rssBytes\":7290880,\"vmsBytes\":726827008,\"swapBytes\":0,\"time\":\"2021-02-10T21:14:07Z\",\"message\":\"server performance\"}"

    const j8a4 = "λ curl -v http://localhost:8080/get\n" +
        "*   Trying ::1...\n" +
        "< HTTP/1.1 200 OK\n" +
        "< Content-Encoding: identity\n" +
        "< Content-Length: 38\n" +
        "< Server: j8a v0.7.3 5772c4a6\n" +
        "< X-Request-Id: XR-5772c4a6-90677d9e-0d53-4b63-8098-0c40f7f0f039\n" +
        "< Date: Wed, 10 Feb 2021 21:20:50 GMT\n" +
        "<\n" +
        "* Connection #0 to host localhost left intact\n" +
        "{\"mse6\":\"Hello from the get endpoint\"}\n" +
        "λ"

    const j8a5 = "{\n" +
        "  \"level\": \"info\",\n" +
        "  \"srvId\": \"5772c4a6\",\n" +
        "  \"dwnReqPath\": \"/get\",\n" +
        "  \"dwnReqRemoteAddr\": \"172.17.0.1\",\n" +
        "  \"dwnReqMethod\": \"GET\",\n" +
        "  \"dwnReqUserAgent\": \"curl/7.64.1\",\n" +
        "  \"dwnHttpVer\": \"1.1\",\n" +
        "  \"dwnResCode\": 200,\n" +
        "  \"dwnResContentEnc\": \"identity\",\n" +
        "  \"dwnElapsedMicros\": 1934,\n" +
        "  \"X-Request-Id\": \"XR-5772c4a6-90677d9e-0d53-4b63-8098-0c40f7f0f039\",\n" +
        "  \"upReqURI\": \"http://host.docker.internal:8081/mse6/get\",\n" +
        "  \"upLabel\": \"default\",\n" +
        "  \"upAtmptResCode\": 200,\n" +
        "  \"upAtmptElapsedMicros\": 1847,\n" +
        "  \"upAtmptAbort\": false,\n" +
        "  \"upAtmpt\": \"1/1\",\n" +
        "  \"time\": \"2021-02-10T21:20:43Z\",\n" +
        "  \"message\": \"downstream response served\"\n" +
        "}"

    const mse6 = "λ docker pull simonmittag/mse6 &&\n" +
        "docker run -p8081:8081 simonmittag/mse6";

    const mse62 = "λ curl http://localhost:8081/mse6/get\n" +
        "{\"mse6\":\"Hello from the get endpoint\"}\n" +
        "λ "

    return (
        <Container fluid={"xl"} id="stats">
            <h1 className="h1_">Getting Started</h1>
            <p className="p_">
                TLDR; In this simple tutorial for Mac OSX we will get J8a up and running in five minutes.
                We'll start a back-end web server, then configure j8a to route traffic to it as a proxy
                on your local machine. You will require a copy of docker running
                on your workstation, and a terminal.
            </p>
            <h3 className="h3_">Step 1: Start a local web server</h3>
            <CopyBlock text={mse6}
                       language="text"
                       highlight=""
                       theme={theme} />
            <p className="p_">
                This will pull down a tiny HTTP server that sends us pre-canned JSON responses
                for testing. Verify it is running like so.
            </p>
            <CopyBlock text={mse62}
                       language="text"
                       highlight=""
                       theme={theme} />
            <p className="p_">
                You are now serving JSON/HTTP locally. Please note this
                server is running on port 8081 by default. If this is unavailable on your system,
                you can update the port directly after <code>-p</code> using the <code>docker run</code> command.
            </p>

            <h3 className="h3_">Step 2: Download j8a</h3>
            <p className="p_">
                We're now ready to install J8a. In this instance, we'll fetch the dockerized version as opposed
                to building it from scratch.
            </p>
            <CopyBlock text={j8a}
                       language="text"
                       theme={theme} />
            <p className="p_">
                That's it. Now all you have to do is set it up.
            </p>

            <h3 className="h3_">Step 3: Configure a route</h3>
            <p className="p_">
                Next, let's set-up a simple route that rewrites all traffic to URLs prefixed with <code>/mse6/</code>,
                which is the URL stem of the back-end above. We'll also proxy from
                the default HTTP port <code>8080</code> to <code>8081</code> on the upstream resource. You'll
                notice host is set to <code>host.docker.internal</code> which is a <code>localhost</code> alias for docker on
                Mac OSX. Adjust this as necessary if you are running on another
                OS. The entire configuration is supplied to the server process as an environment variable.
                Run the below in your terminal.
            </p>
            <CopyBlock text={j8a2}
                       language="text"
                       theme={theme} />
            <p className="p_">
                You're almost done. Have you ever configured a proxy server with so little code?
            </p>

            <h3 className="h3_">Step 4: Launch J8a</h3>
            <p className="p_">
                Now all you have to do is launch a j8a container, pointing at the configuration you previously set.
            </p>
            <CopyBlock text={j8a3}
                       language="text"
                       theme={theme} />
            <p className="p_">
                That's it. You will see output similar to the above, with the server listening on port <code>8080</code>.
                Let's hit it with a HTTP request.
            </p>
            <CopyBlock text={j8a4}
                       language="text"
                       theme={theme} />
            <p className="p_">
                You should see a response similar to the above. Notice the <code>X-Request-Id</code> header? It is
                auto generated for each incoming request and you will find matching entries in the server log. Have a look
                at the j8a's stdout to find your HTTP request metadata.
            </p>
            <CopyBlock text={j8a5}
                       language="json"
                       theme={theme} />
            <p className="p_">
                That concludes the getting started guide. This five minute tutorial only scratches the surface of
                what j8a can do. Refer to the <Link to={"/docs"}>docs</Link> for a more in depth overview of config
                options.
            </p>
        </Container>
    );
}

export default Gettingstarted;
