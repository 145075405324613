import Welcome from "../components/welcome/welcome";
import React from "react";
import Features from "../components/features/features";
import Gettingstarted from "../components/gettingstarted/gettingstarted";

function Home() {
    return (
        <>
            <Welcome />
            <Features />
            <Gettingstarted />
        </>
    );
}

export default Home;
